import React from 'react';
import RiskBy3 from './RiskBy3';
import RiskBy5 from './RiskBy5';

const RiskAssessment = ({ view, risk_options }) => {
    return (
        <div className="mt-[5rem]">
            <RiskBy3 view={view} risk_options={risk_options} />
            <RiskBy5 view={view} risk_options={risk_options} />
        </div>
    );
};

export default RiskAssessment;
