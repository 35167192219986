import React, { useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, IconButton, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';

// core components
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import { connect, useSelector } from 'react-redux';
import TipsEnableButton from './TipsEnableButton';
import Vifly from 'assets/img/vifly_.svg';
import CustomTooltip from 'components/new_components/CustomTooltip';
import { useLocation } from 'react-router-dom';

const MerchantHeader = (props) => {
    const theme = useTheme();
    let location = useLocation();

    const { openDrawer, title, openNotificationModal, notificationModalOpen, subtitle, openAIModal } = props;
    const user_info = useSelector((state) => state?.generalReducers?.user_info);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location?.pathname]);
    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: 'calc(100% - 275px)' },
                ml: { md: '275px' },
                backgroundColor: theme.palette.white.main,
                justifyContent: 'center',
                // height: { xs: 70, md: 90 },
                boxShadow: 'none',
                zIndex: 800,
                mt: user_info?.renewal_request_info?.show_banner ? '3rem' : 0,
                // borderBottom: title?.toLowerCase() === 'soc2' ? '1px solid black' :'4px solid ' + theme.palette.primary[100],
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={openDrawer}
                    sx={{ display: { md: 'none' } }}
                >
                    <Menu color="primary" sx={{ fontSize: '2rem' }} />
                </IconButton>
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Typography
                        variant="h1"
                        noWrap
                        component="h1"
                        sx={{
                            m: 0,
                            fontSize: 18,
                            fontWeight: 700,
                        }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h1"
                        noWrap
                        component="h1"
                        sx={{
                            m: 0,
                            fontSize: 15,
                        }}
                    >
                        {subtitle}
                    </Typography>
                </Box>
                <CustomTooltip title="Ask Vifly AI" placement="bottom">
                    <div className="cursor-pointer" onClick={openAIModal}>
                        <img src={Vifly} alt="Vifly" />
                    </div>
                </CustomTooltip>
                <NotificationDropdown
                    openNotificationModal={openNotificationModal}
                    notificationModalOpen={notificationModalOpen}
                />
                <TipsEnableButton />
                <UserDropdown />
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = (state) => {
    return {
        organization: state?.generalReducers?.user_info?.organization,
    };
};

export default connect(mapStateToProps, {})(MerchantHeader);
