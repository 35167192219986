// import React, { useEffect, useState } from 'react';
// import './carousel.css';
// import { Box, useTheme } from '@mui/material';

// const Carousel = ({ slides, handleOpen, type, data }) => {
//     const theme = useTheme();
//     const [currentIndex, setCurrentIndex] = useState(0);
//     const [hover, setHover] = useState(false);
//     const slideHeight = 33;
//     const slideHeight1 = 70;
//     useEffect(() => {
//         if (hover) return;
//         let intervalId = setInterval(() => {
//             setCurrentIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
//         }, 9000);

//         return () => {
//             clearInterval(intervalId);
//         };
//     }, [hover]);
//     const handleOpenModal = (slide) => {
//         const filtered_data = Object?.keys(data)
//             ?.map((item) => {
//                 return {
//                     key: item,
//                     value: data[item],
//                 };
//             })
//             ?.filter((item) => item?.value?.length > 0)
//             ?.find((item) => item?.value.includes(slide));
//         handleOpen(slide, type, filtered_data?.key);
//     };
//     return (
//         <div className="carousel-container" id="carousel">
//             <Box
//                 // className="carousel-slides"
//                 sx={{
//                     transform: `translateY(-${currentIndex * slideHeight}px)`,
//                     // marginBottom: '5px',
//                     transition: 'transform 1s ease-in-out',
//                     [theme.breakpoints.down('2xl')]: {
//                         transform: `translateY(-${currentIndex * slideHeight1}px)`,
//                     },
//                     [theme.breakpoints.down('xl')]: {
//                         transform: `translateY(-${currentIndex * slideHeight1}px)`,
//                     },
//                 }}
//             >
//                 {slides.map((slide, index) => (
//                     <div
//                         key={index}
//                         // className={`2xl:h-[${slideHeight}px] xl:h-[${slideHeight1}px] md:h-[${slideHeight1}px] carousel-slide cursor-pointer`}
//                         className={`carousel-slide cursor-pointer `}
//                         // sx={{
//                         //     height: `${slideHeight}px`,
//                         //     [theme.breakpoints.down('2xl')]: {
//                         //         height: `${slideHeight1}px`,
//                         //     },
//                         //     [theme.breakpoints.down('xl')]: {
//                         //         height: `${slideHeight1}px`,
//                         //     },
//                         // }}
//                         onClick={() => handleOpenModal(slide)}
//                         onMouseOver={() => setHover(true)}
//                         onMouseLeave={() => setHover(false)}
//                     >
//                         <h4 className="text-[#475569] text-[13px] font-medium">
//                             {(slide?.title || slide?.overview)?.substring(0, 50)}
//                             {(slide?.title || slide?.overview)?.length > 50 && '...'}
//                         </h4>
//                     </div>
//                 ))}
//             </Box>
//         </div>
//     );
// };

// export default Carousel;

import React from 'react';
import Slider from 'react-slick';
import './carousel.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({ slides, handleOpen, type, data, view }) => {
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 8000,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <></>,
        prevArrow: <></>,
    };
    console.log({ slides });
    const handleOpenModal = (slide) => {
        const filtered_data = Object?.keys(data)
            ?.map((item) => {
                return {
                    key: item,
                    value: data[item],
                };
            })
            ?.filter((item) => item?.value?.length > 0)
            ?.find((item) => item?.value.includes(slide));
        handleOpen(slide, type, filtered_data?.key);
    };
    return (
        <div className="w-full">
            <Slider {...settings}>
                {slides.map((slide, index) => (
                    <div key={index}>
                        <div onClick={() => handleOpenModal(slide)} className="cursor-pointer xl:block md:hidden">
                            {view === 'compliance' ? (
                                <h4 className="text-[#475569] text-[13px] font-medium">
                                    {(slide?.title || slide?.overview)?.substring(0, 200)}
                                    {(slide?.title || slide?.overview)?.length > 200 && '...'}{' '}
                                </h4>
                            ) : (
                                <h4 className="text-[#475569] text-[13px] font-medium">
                                    {(slide?.title || slide?.overview)?.substring(0, 60)}
                                    {(slide?.title || slide?.overview)?.length > 60 && '...'}{' '}
                                </h4>
                            )}
                        </div>

                        <div onClick={() => handleOpenModal(slide)} className="cursor-pointer xl:hidden md:block">
                            {view === 'compliance' ? (
                                <h4 className="text-[#475569] text-[13px] font-medium">
                                    {(slide?.title || slide?.overview)?.substring(0, 150)}
                                    {(slide?.title || slide?.overview)?.length > 150 && '...'}{' '}
                                </h4>
                            ) : (
                                <h4 className="text-[#475569] text-[13px] font-medium">
                                    {(slide?.title || slide?.overview)?.substring(0, 50)}
                                    {(slide?.title || slide?.overview)?.length > 50 && '...'}{' '}
                                </h4>
                            )}
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;
