import React, { useState, useEffect, useMemo } from 'react';
import { Box, Typography, Stack, Button, Grid } from '@mui/material';
import { ErrorOutline, AddCircle } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
//core components
import { toast } from 'react-toastify';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import AppSelectInput from 'components/new_components/AppSelectInput';
import AppInput from 'components/new_components/AppInput';
//utils
//redux
import { connect } from 'react-redux';
import { RequestPentest, UpdatePentestRequest } from 'store/actions/merchantActions';
import PentestVendorTab from './components/PentestVendorTab';
import {
    assetsOptions,
    targetEnvironmentOptions,
    scheduleOptions,
    booleanOptions,
    testTimeOptions,
} from './utils/constants';
import PentestDrawer from './components/PentestDrawer';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import AppTagInputField from 'components/new_components/AppTagInputField';
import lightBulb from '../../../assets/img/lightBulb.svg';
import removeDisabled from '../../../assets/img/removeDisabled.svg';
import removeActive from '../../../assets/img/removeActive.svg';
import enable from '../../../assets/img/enable.svg';
import disable from '../../../assets/img/disable.svg';

//translations
import { useTranslation } from 'react-i18next';

const RequestModal = ({ open, handleClose, mode, payload, RequestPentest, UpdatePentestRequest }) => {
    const [loadingState, setloadingState] = useState(false);
    const [assets, setAssets] = useState([]);
    const [formValues, setFormValues] = useState({});
    const [view, setView] = useState('Wired Assurance');
    const [authorization, setAuthorization] = useState(false);
    const [unpermittedInputValue, setUnpermittedInputValue] = useState('');
    const [unpermittedTags, setUnpermittedTags] = useState([]);
    const [isUnPermittedKeyReleased, setIsUnPermittedKeyReleased] = useState(false);
    const [thirdPartiesInputValue, setThirdPartiesInputValue] = useState('');
    const [thirdPartiesTags, setThirdPartiesTags] = useState([]);
    const [isThirdPartiesKeyReleased, setIsThirdPartiesKeyReleased] = useState(false);

    const theme = useTheme();

    //translation
    const { t } = useTranslation('pentest');

    //sorted Schedule options
    const sortedScheduleOptions = useMemo(() => {
        return scheduleOptions?.filter((option) => option.name !== 'Run Now');
    }, [scheduleOptions, mode]);

    //function
    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const closeModal = () => {
        handleClose();
        setAssets([
            {
                id: 1,
                asset: '',
                ip_address: '',
                stack: '',
                enabled: true,
            },
        ]);
        setAuthorization(false);
        setFormValues({});
        setUnpermittedTags([]);
        setThirdPartiesTags([]);
        setView('Wired Assurance');
    };

    const handleChangeView = (value) => {
        setView(value);
    };
    //handle inputs
    const handleItemInputChange = (e, index) => {
        const { name, value } = e.target;
        const newItems = assets || [];
        newItems[index] = { ...newItems?.[index], [name]: value };
        newItems[index] = {
            ...newItems?.[index],
        };

        setAssets([...newItems]);
    };
    const handleEnableAsset = (index) => {
        const newItems = assets || [];
        newItems[index] = { ...newItems?.[index], enabled: true };
        newItems[index] = {
            ...newItems?.[index],
        };

        setAssets([...newItems]);
    };
    const handleDisableAsset = (index) => {
        const newItems = assets || [];
        newItems[index] = { ...newItems?.[index], enabled: false };
        newItems[index] = {
            ...newItems?.[index],
        };

        setAssets([...newItems]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    };

    //handle add and remove assets
    const handleAddItem = () => {
        const newItem = {
            asset: '',
            ip_address: '',
            stack: '',
            enabled: true,
        };
        const assetsCopy = [...assets];
        assetsCopy.push(newItem);
        setAssets(assetsCopy);
    };
    const removeItem = (index) => {
        const newAssets = [...assets];
        newAssets?.splice(index, 1);
        setAssets(newAssets);
    };

    //handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (authorization) {
            setloadingState(true);
            const values =
                view.toLowerCase() === 'cobalt'
                    ? {
                          ...formValues,
                          authorization: authorization,
                          cobalt: true,
                      }
                    : {
                          ...formValues,
                          authorization: authorization,
                          unpermitted_hosts: unpermittedTags?.join(','),
                          thirdparties: thirdPartiesTags?.join(','),
                          assets: assets,
                          cobalt: false,
                      };
            const res =
                mode === 'view'
                    ? await UpdatePentestRequest(payload?.id, { schedule: formValues?.schedule })
                    : mode === 'rerun'
                    ? await RequestPentest({ ...values, rerun: true, rerun_id: payload?.id })
                    : await RequestPentest(values);
            setloadingState(false);
            if (res?.success) {
                toast.success(
                    mode === 'view'
                        ? 'Updated Successfully'
                        : mode === 'rerun'
                        ? 'Rerun Successful!'
                        : 'Successful Request'
                );
                closeModal();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };

    //useEffect
    useEffect(() => {
        setAssets([
            {
                asset: '',
                ip_address: '',
                stack: '',
                enabled: true,
            },
        ]);
    }, []);

    useEffect(() => {
        if (mode === 'view' || mode === 'rerun') {
            setFormValues({
                name: payload?.name,
                schedule: payload?.runschedule,
                security_concerns: payload?.security_concerns,
                target_env: payload?.target_env,
                test_time: payload?.test_time,
            });
            setAssets(payload?.assets);
            setAuthorization(payload?.authorization);
            setUnpermittedTags(payload?.unpermitted_hosts?.split(','));
            setThirdPartiesTags(payload?.thirdparties?.split(','));
        }
    }, [mode, payload]);

    return (
        <PentestDrawer
            open={open}
            handleClose={closeModal}
            title={
                <div className="flex items-center gap-[8px]">
                    <span className="text-[#202D66] font-semibold text-[18px]">
                        {mode === 'request'
                            ? t('pentestRequestModal.title1')
                            : mode === 'rerun'
                            ? `${t('pentestRequestModal.title2')}`
                            : t('pentestRequestModal.title3')}
                    </span>
                </div>
            }
        >
            <Box component="form" onSubmit={handleSubmit}>
                <Stack sx={{ width: '100%' }} direction="column" justifyContent="center" alignItems="center">
                    {mode === 'request' ? (
                        <PentestVendorTab onClick={handleChangeView} view={view} />
                    ) : (
                        <Stack
                            direction="row"
                            sx={{
                                fontSize: '12px',
                                color: theme.palette.gray[40],
                                fontWeight: 400,
                                p: 0.5,
                                mx: 1,
                                mt: 0.8,
                            }}
                        >
                            <div
                                className={`flex items-start text-[14px] mt-[16px] border-[#f0f0fa] px-[16px] py-[8px] rounded-[4px] gap-[8px] text-[#64748B] border-[1px] border-solid bg-[#f8fafc]                                    ] ${
                                    mode === 'view' ? 'text-[#DCDEE5]' : 'text-[#15161A]'
                                }`}
                            >
                                <img src={lightBulb} alt="lightBulb" />
                                <span>
                                    {mode === 'rerun'
                                        ? t('pentestRequestModal.subtitle1')
                                        : t('pentestRequestModal.subtitle2')}
                                </span>
                            </div>
                        </Stack>
                    )}
                    {view.toLowerCase() === 'cobalt' && (
                        <Typography
                            sx={{
                                fontSize: '12px',
                                color: theme.palette.gray[40],
                                fontWeight: 400,
                                p: 0.5,
                                mx: 1,
                                mt: 0.8,
                            }}
                        >
                            <ErrorOutline color={theme.palette.gray[40]} fontSize="small" />{' '}
                            {t('pentestRequestModal.cobalt1')}
                            <span className="font-semibold">{t('pentestRequestModal.cobalt2')}</span>{' '}
                            {t('pentestRequestModal.cobalt3')}
                        </Typography>
                    )}
                </Stack>
                <Box
                    sx={{
                        mx: 2,
                        pr: 1.8,
                        overflow: 'scroll !important',
                        height: '83vh',
                        [theme.breakpoints.down('lg')]: {
                            height: '83vh',
                        },
                        mt: 3,
                    }}
                >
                    {view.toLowerCase() !== 'cobalt' ||
                        (mode === 'rerun' && (
                            <AppInput
                                type="text"
                                label={t('pentestRequestModal.securityConcerns')}
                                name="security_concerns"
                                multiline
                                value={formValues?.security_concerns}
                                onChange={handleInputChange}
                                required
                                toolTip={true}
                                disabled={mode === 'view'}
                                tips={t('pentestRequestModal.tips')}
                            />
                        ))}
                    {mode !== 'rerun' && (
                        <Grid container spacing={'16px'} style={{ marginTop: '-30px' }}>
                            <Grid item xs={12} md={6} lg={12}>
                                <AppInput
                                    type="text"
                                    label={t('pentestRequestModal.scanName')}
                                    name="name"
                                    placeholder={t('pentestRequestModal.scanName')}
                                    value={formValues?.name}
                                    onChange={handleInputChange}
                                    required
                                    disabled={mode === 'view'}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={12} style={{ marginTop: '-15px' }}>
                                <AppSelectInput
                                    name="schedule"
                                    label={t('pentestRequestModal.schedule')}
                                    toolTip={true}
                                    tips={
                                        <>
                                            {t('pentestRequestModal.scheduleTip1')}
                                            <ul className="list-disc ml-[0.8rem]">
                                                <li>{t('pentestRequestModal.scheduleTip2')}</li>
                                                <li>{t('pentestRequestModal.scheduleTip3')}</li>
                                                <li>{t('pentestRequestModal.scheduleTip4')}</li>
                                            </ul>
                                        </>
                                    }
                                    onChange={handleInputChange}
                                    value={formValues?.schedule}
                                    options={sortedScheduleOptions}
                                    required
                                />
                            </Grid>
                        </Grid>
                    )}
                    {view.toLowerCase() === 'cobalt' && (
                        <Grid container spacing={0}>
                            <Grid item xs={12} md={6} lg={6}>
                                <AppInput
                                    type="text"
                                    label={t('pentestRequestModal.ipAddress')}
                                    name="ip_address"
                                    placeholder={t('pentestRequestModal.ipAddress')}
                                    onChange={handleInputChange}
                                    value={formValues?.ip_address}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <AppInput
                                    type="text"
                                    label={t('pentestRequestModal.techStack')}
                                    name="stack"
                                    placeholder={t('pentestRequestModal.stack')}
                                    onChange={handleInputChange}
                                    value={formValues?.stack}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <AppSelectInput
                                    options={targetEnvironmentOptions}
                                    label={t('pentestRequestModal.targetEnv')}
                                    name="target_env"
                                    onChange={handleInputChange}
                                    defaultValue={t('pentestRequestModal.targetEnvValue')}
                                    value={formValues?.target_env}
                                    required
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <AppSelectInput
                                    name="assets"
                                    label={t('pentestRequestModal.asset')}
                                    value={formValues?.asset}
                                    options={assetsOptions}
                                    onChange={handleInputChange}
                                    defaultValue={t('pentestRequestModal.assetValue')}
                                    required={true}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <AppSelectInput
                                    name="is_cloud"
                                    label={t('pentestRequestModal.cloud')}
                                    options={booleanOptions}
                                    defaultValue={t('pentestRequestModal.cloudValue')}
                                    onChange={handleInputChange}
                                    value={formValues?.is_cloud}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={6}>
                                <AppSelectInput
                                    name="sensitive_data"
                                    label={t('pentestRequestModal.sensitiveData')}
                                    options={booleanOptions}
                                    defaultValue={t('pentestRequestModal.sensitiveDataValue')}
                                    onChange={handleInputChange}
                                    value={formValues?.sensitive_data}
                                />
                            </Grid>
                            <Grid item xs={12} md={12} lg={12}>
                                <AppInput
                                    type="text"
                                    label={t('pentestRequestModal.objectives')}
                                    name="objectives"
                                    multiline
                                    value={formValues?.objectives}
                                    onChange={handleInputChange}
                                    toolTip={true}
                                    required
                                    tips={t('pentestRequestModal.tips')}
                                />
                            </Grid>
                        </Grid>
                    )}
                    <div className="bg-[#e2e8f0] h-[1px] w-[100%] mt-[16px] mb-[8px]"></div>
                    <span className="text-[13px] font-semibold text-[#395ba9]">
                        {t('pentestRequestModal.assetsInScope')}
                    </span>
                    {view.toLowerCase() !== 'cobalt' && (
                        <>
                            {mode !== 'rerun' && (
                                <ul
                                    className={`flex items-start text-[14px] mt-[16px] border-[#f0f0fa] px-[16px] py-[8px] rounded-[4px] text-[#64748B] border-[1px] border-solid bg-[#f8fafc]                                    ] ${
                                        mode === 'view' ? 'text-[#DCDEE5]' : 'text-[#15161A]'
                                    }`}
                                >
                                    {mode === 'view' ? <img src={lightBulb} alt="lightBulb" /> : null}
                                    <li>{t('pentestRequestModal.host')}</li>
                                </ul>
                            )}

                            <Grid container spacing={'16px'}>
                                {assets?.map((asset, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <Grid item xs={12} md={4} lg={6} width="100%">
                                                <AppSelectInput
                                                    name="asset"
                                                    label={t('pentestRequestModal.asset')}
                                                    value={asset?.asset}
                                                    options={assetsOptions}
                                                    onChange={(e) => handleItemInputChange(e, index)}
                                                    defaultValue={t('pentestRequestModal.assetValue')}
                                                    required={true}
                                                    toolTip={true}
                                                    tips={t('pentestRequestModal.tips')}
                                                    disabled={
                                                        mode === 'view' ||
                                                        mode === 'rerun' ||
                                                        (mode === 'rerun' && !asset?.enabled)
                                                    }
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={6} width="100%">
                                                <AppInput
                                                    type="text"
                                                    label={t('pentestRequestModal.ipAddress/URL')}
                                                    name="ip_address"
                                                    value={asset?.ip_address}
                                                    onChange={(e) => handleItemInputChange(e, index)}
                                                    placeholder={t('pentestRequestModal.ipOrURL')}
                                                    required={true}
                                                    disabled={
                                                        mode === 'view' ||
                                                        mode === 'rerun' ||
                                                        (mode === 'rerun' && !asset?.enabled)
                                                    }
                                                    // regex={pattern.ip_address}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={4} lg={12} width="100%">
                                                <AppInput
                                                    type="text"
                                                    label={t('pentestRequestModal.techStack')}
                                                    name="stack"
                                                    onChange={(e) => handleItemInputChange(e, index)}
                                                    value={asset?.stack}
                                                    placeholder={t('pentestRequestModal.stack')}
                                                    required={true}
                                                    toolTip={true}
                                                    tips={
                                                        <>
                                                            {t('pentestRequestModal.techStackTip1')}
                                                            <ul className="list-disc ml-[0.8rem]">
                                                                <li>{t('pentestRequestModal.techStackTip2')}</li>
                                                                <li>{t('pentestRequestModal.techStackTip3')}</li>
                                                                <li>{t('pentestRequestModal.techStackTip4')}</li>
                                                            </ul>
                                                        </>
                                                    }
                                                    disabled={
                                                        mode === 'view' ||
                                                        mode === 'rerun' ||
                                                        (mode === 'rerun' && !asset?.enabled)
                                                    }
                                                />
                                            </Grid>
                                            {mode === 'rerun' && (
                                                <Grid item xs={12} lg={12} md={12} sx={{ mt: '-1rem' }}>
                                                    <Stack direction="row" justifyContent="flex-end">
                                                        <Button
                                                            sx={{
                                                                textTransform: 'unset',
                                                                mb: '-25px',
                                                                '&:hover': {
                                                                    background: 'none',
                                                                },
                                                            }}
                                                            startIcon={
                                                                asset?.enabled ? (
                                                                    <img src={disable} alt="enable icon" />
                                                                ) : (
                                                                    <img src={enable} alt="disable icon" />
                                                                )
                                                            }
                                                            color={asset?.enabled ? 'error' : 'success'}
                                                            onClick={
                                                                asset?.enabled
                                                                    ? () => handleDisableAsset(index)
                                                                    : () => handleEnableAsset(index)
                                                            }
                                                            type="button"
                                                        >
                                                            {asset?.enabled
                                                                ? t('pentestRequestModal.disableAsset')
                                                                : t('pentestRequestModal.enableAsset')}
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                            )}
                                            <Grid item xs={12} lg={12} md={12} sx={{ mt: '-1rem' }}>
                                                <Stack
                                                    direction="row"
                                                    justifyContent="flex-end"
                                                    style={{
                                                        fontSize: '13px',
                                                        fontWeight: 500,
                                                    }}
                                                >
                                                    <Button
                                                        style={{
                                                            marginTop: '15px',
                                                            marginRight: '-8px',

                                                            color:
                                                                assets?.length === 1 || mode === 'view'
                                                                    ? '#94a3b8'
                                                                    : '#ff5449',
                                                        }}
                                                        sx={{
                                                            textTransform: 'unset',

                                                            '&:hover': {
                                                                background: 'none',
                                                            },
                                                        }}
                                                        startIcon={
                                                            <>
                                                                {assets?.length === 1 || mode === 'view'
                                                                    ? mode === 'request' && (
                                                                          <img src={removeDisabled} alt="remove icon" />
                                                                      )
                                                                    : mode === 'request' && (
                                                                          <img src={removeActive} alt="remove icon" />
                                                                      )}
                                                            </>
                                                        }
                                                        color="error"
                                                        onClick={() => removeItem(index)}
                                                        type="button"
                                                        disabled={
                                                            assets?.length === 1 ||
                                                            mode === 'view' ||
                                                            mode === 'request'
                                                        }
                                                    >
                                                        {mode === 'rerun' ? (
                                                            <div className="hidden"></div>
                                                        ) : (
                                                            t('pentestRequestModal.removeAsset')
                                                        )}
                                                    </Button>
                                                </Stack>
                                            </Grid>
                                        </React.Fragment>
                                    );
                                })}
                                <div className="h-[1px] w-[100%] bg-[#e2e8f0]"></div>
                            </Grid>
                            {mode !== 'rerun' && (
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    {assets?.length < 3 && (
                                        <Button
                                            sx={{
                                                textTransform: 'unset',

                                                '&:hover': {
                                                    background: 'none',
                                                },
                                            }}
                                            startIcon={<AddCircle />}
                                            onClick={handleAddItem}
                                            type="button"
                                            disabled={mode === 'view'}
                                            style={{
                                                marginTop: '-40px',
                                                color: '#395ba9',
                                                fontSize: '13px',
                                                fontWeight: 500,
                                                marginLeft: '-4px',
                                            }}
                                        >
                                            {t('pentestRequestModal.addAsset')}
                                        </Button>
                                    )}
                                </Stack>
                            )}

                            <ul
                                className={`flex items-start text-[14px] mt-[16px] border-[#f0f0fa] px-[16px] py-[8px] rounded-[4px] text-[#64748B] border-[1px] border-solid bg-[#f8fafc]                                    ] ${
                                    mode === 'view' ? 'text-[#DCDEE5]' : 'text-[#15161A]'
                                }`}
                            >
                                {mode === 'view' ? <img src={lightBulb} alt="lightBulb" /> : null}
                                <li> {t('pentestRequestModal.testingTip')}</li>
                            </ul>
                            <AppTagInputField
                                name="unpermitted_hosts"
                                label={t('pentestRequestModal.unpermittedHost')}
                                tagInputValue={unpermittedInputValue}
                                setTagInputValue={setUnpermittedInputValue}
                                tags={unpermittedTags.filter((tag) => tag !== '')}
                                setTags={setUnpermittedTags}
                                isKeyReleased={isUnPermittedKeyReleased}
                                setIsKeyReleased={setIsUnPermittedKeyReleased}
                                placeholder="Exclude the following hosts/networks from testing"
                                disabled={mode === 'view'}
                            />
                            <AppTagInputField
                                name="thirdparties"
                                label={t('pentestRequestModal.thirdParties')}
                                tagInputValue={thirdPartiesInputValue}
                                setTagInputValue={setThirdPartiesInputValue}
                                tags={thirdPartiesTags.filter((tag) => tag !== '')}
                                setTags={setThirdPartiesTags}
                                isKeyReleased={isThirdPartiesKeyReleased}
                                setIsKeyReleased={setIsThirdPartiesKeyReleased}
                                placeholder="Third-party systems/networks in scope"
                                toolTip={true}
                                tips={t('pentestRequestModal.thirdPartiesTip')}
                                disabled={mode === 'view'}
                            />
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6} lg={12}>
                                    <AppSelectInput
                                        options={targetEnvironmentOptions}
                                        label={t('pentestRequestModal.targetEnv')}
                                        name="target_env"
                                        onChange={handleInputChange}
                                        value={formValues?.target_env}
                                        required
                                        disabled={mode === 'view'}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={12}>
                                    <AppSelectInput
                                        options={testTimeOptions}
                                        label={t('pentestRequestModal.testTime')}
                                        name="test_time"
                                        onChange={handleInputChange}
                                        value={formValues?.test_time}
                                        required
                                        disabled={mode === 'view'}
                                    />
                                </Grid>
                            </Grid>
                        </>
                    )}

                    <div style={{ marginBottom: '125px' }}>
                        <AppCheckboxInput
                            name="authorization"
                            label={t('pentestRequestModal.authorization')}
                            onChange={handleAuthorizationCheck}
                            value={authorization}
                        />
                    </div>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        gap: '8px',
                        mx: 2,
                        mb: 1.5,
                        background: '#f8fafc',
                        height: '84px',
                        marginLeft: '-5px',
                        marginRight: '0',
                        paddingRight: '24px',
                        position: 'relative',
                        // bottom: mode === 'request' ? '-20px' : '-120px',
                    }}
                >
                    <button className="flex items-center text-[14px] font-medium bg-[#fff] justify-center border-[1px] border-solid border-[#e2e8f0] rounded-[2px] w-[83px] h-[36px]">
                        Cancel
                    </button>
                    <AppLoadingButton
                        text={
                            mode === 'request'
                                ? t('pentestRequestModal.request')
                                : mode === 'rerun'
                                ? t('pentestRequestModal.rerunRequest')
                                : t('pentestRequestModal.updateScheduling')
                        }
                        variant="contained"
                        color="primary"
                        type="submit"
                        loading={loadingState}
                        loadingPosition="center"
                        sx={{ width: 'fit-content', boxShadow: 'none' }}
                    />
                </Box>
            </Box>
        </PentestDrawer>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { RequestPentest, UpdatePentestRequest })(RequestModal);
