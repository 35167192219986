/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import { ReactComponent as Logo } from 'assets/img/brand/logo.svg';
import WestIcon from '@mui/icons-material/West';
import { Checkbox, Dialog, Drawer, FormControlLabel, Menu, MenuItem, Slide } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { employeeOptions, inventoryOptions, riskOptions, thirdpartyOptions, viewOptions } from '../utils/constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AutomatedScans from './AutomatedScans';
import RiskAssessment from './RiskAssessment';
import Employees from './Employees';
import EmployeesTable from './EmployeesTable';
import ReportSummary2 from './ReportSummary2';
import ReportSummary1 from './ReportSummary1';
import Standards from './Standards';
import InverntoryScan from './InventoryScan';
import InventoryScansTable from './InventoryScansTable';
import ThirdParty from './ThirdParty';
import {
    GetPeopleInfoSummary,
    GetPeopleSummary,
    GetPeopleTrainingInfoSummary,
    GetMerchantNDPRSummaryByCategory,
    GetMerchantKDPASummaryByCategory,
    GetMerchantGDPASummaryByCategory,
    GetMerchantSoc2SummaryByControl,
    GetMerchantOverviewSummary,
    GetMerchantPcidss4point0OverviewSummary,
    GetMerchantIsoSummaryByAnnex,
    GetMerchantTechSecSummaryByTechSec,
    GetMerchantIsoTwoSummaryByAnnex,
    GetMerchantPCISLCGeneralSummary,
    GetMerchantISO9001SummaryByManagement,
    GetContinuityGroupSummary,
    GetPeopleScanSummary,
    GetInventoryScanSummary,
    GetSourceCodeScanSummary,
    GetAPISecurityScanSummary,
    GetPCIcanSummary,
    GetVulScanSummary,
    GetAllVendorSummary,
    GetAllKycSummary,
    GetAllFraudMonitoringSummary,
    GetAllMerchantRiskAssessments,
    GetAllRiskAssessmentsSummary,
    GetAllPentestSummary,
} from 'store/actions/cisoActions';
import { GetAutomatedScanGroups } from 'store/actions/automatedScansActions';
import { Loader2 } from 'lucide-react';
import { GetAutomatedScansSummary } from 'store/actions/cisoActions';
import { GetThirdPartyInfoSummary } from 'store/actions/cisoActions';
import { GetMerchantPCISLCSummary } from 'store/actions/cisoActions';
import { GetMerchantBlockhainSummary } from 'store/actions/cisoActions';
import { GetMerchantCiiDirectiveSummary } from 'store/actions/cisoActions';
import { GetMerchantGeneralBlockhainSummary } from 'store/actions/cisoActions';
import { GetMerchantGeneralCiiDirectiveSummary } from 'store/actions/cisoActions';
import { GetFrameworkPercentageSummary } from 'store/actions/cisoActions';
import { GetCisoPercentageSummary } from 'store/actions/cisoActions';
import SpoolReportPrint from './SpoolReport';
import moment from 'moment';
import { generatePFDReport } from 'utils';
import { GetAllTrainingCourses } from 'store/actions/trainingActions';
import { GetMerchantPeople } from 'store/actions/merchantActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

function SpoolVendorReport({ open, handleClose }) {
    const date_joined = useSelector((state) => state?.authReducers?.user_details?.user?.date_joined) ?? null;
    const yearUserSignedUp = date_joined ? moment(date_joined)?.year() : moment()?.year();
    console.log({ date_joined });

    // redux
    const [itemLength, setItemLength] = useState(0);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);
    const all_automated_scan_group = useSelector((state) => state?.automatedScansReducers?.all_automated_scan_group);
    const { ciso_percentage_ciso_summary } = useSelector((state) => state?.CISOReducers);
    const filtered_standards = merchant_info?.standards
        ?.split(',')
        ?.filter((standard) => standard !== 'soc2type2' && standard !== 'pcislc');
    const filtered_checks =
        all_automated_scan_group?.length > 0 ? all_automated_scan_group?.map((item) => item?.name) : [];

    // State
    const [viewAnchorEl, setViewAnchorEl] = useState(null);
    const [filterAnchorEl, setFilterAnchorEl] = useState(null);
    const [view, setView] = useState('All');
    const [automated_scans, setAutomated_scans] = useState([...filtered_checks]);
    const [standards, setStandards] = useState([...new Set(filtered_standards)]);
    const [risk_options, setRisk_options] = useState([...riskOptions]);
    const [employees_options, setEmployees_options] = useState([...employeeOptions]);
    const [inventory_options, setInventory_options] = useState([...inventoryOptions]);
    const [third_party_options, setThirdPartyOptions] = useState([...thirdpartyOptions]);
    const [loading, setLoading] = useState(false);
    const [loadingContent, setLoadingContent] = useState(false);
    const [filterYear, setFilterYear] = useState('');
    const dispatch = useDispatch();

    const handleYearChange = (option) => {
        setFilterYear(option);
    };

    const query_params = useMemo(() => {
        const params = {
            ...(filterYear && {
                year: filterYear,
            }),
        };
        return params;
    }, [filterYear]);

    // Automated scans
    const handleAutomatedScansCheck = (scan) => {
        const modified_scans = [...automated_scans];
        if (automated_scans?.includes(scan)) {
            setAutomated_scans(modified_scans.filter((item) => item !== scan));
        } else {
            setAutomated_scans([...modified_scans, scan]);
        }
    };

    const getMerchantPCISLCSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantPCISLCSummary(query_params));
    };

    const getThirdPartyInfoSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetThirdPartyInfoSummary(query_params));
    };

    const getAutomatedScansSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAutomatedScansSummary(query_params));
    };

    const getAlutomatedScans = async () => {
        setLoadingContent(true);
        await dispatch(GetAutomatedScanGroups(query_params));
    };

    const getPeopleInfoSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetPeopleInfoSummary(query_params));
    };
    const getPeopleSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetPeopleSummary(query_params));
    };

    const getAllTrainingCourses = async () => {
        setLoadingContent(true);
        await dispatch(GetAllTrainingCourses(query_params));
    };

    const getAllEmployees = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantPeople(query_params));
    };

    const getContinuityGroupSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetContinuityGroupSummary(query_params));
    };
    const getPeopleTrainingInfoSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetPeopleTrainingInfoSummary(query_params));
    };

    const getAllKDPASummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantKDPASummaryByCategory(query_params));
    };

    const getAllGDPASummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantGDPASummaryByCategory(query_params));
    };
    const getMerchantOverviewSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantOverviewSummary(query_params));
    };
    const getAllPcidssSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantPcidss4point0OverviewSummary(query_params));
    };
    const getAllISO9001Summary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantISO9001SummaryByManagement(query_params));
    };

    const getISO22301Summary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantTechSecSummaryByTechSec(query_params));
    };
    const getNdprSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantNDPRSummaryByCategory(query_params));
    };

    const getMerchantIsoSummaryByAnnex = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantIsoSummaryByAnnex(query_params));
    };

    const getMerchantIsoTwoSummaryByAnnex = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantIsoTwoSummaryByAnnex(query_params));
    };
    const getSoc2Summary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantSoc2SummaryByControl(query_params));
    };
    const getSSLCSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantPCISLCGeneralSummary(query_params));
    };

    const getPeopleScanSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetPeopleScanSummary(query_params));
    };
    const getInventoryScanSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetInventoryScanSummary(query_params));
    };

    const getSourceCodeScanSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetSourceCodeScanSummary(query_params));
    };

    const getAPISecurityScanSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAPISecurityScanSummary(query_params));
    };

    const getPCIcanSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetPCIcanSummary(query_params));
    };

    const getVulScanSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetVulScanSummary(query_params));
    };

    const getAllVendorSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAllVendorSummary(query_params));
    };

    const getAllKycSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAllKycSummary(query_params));
    };

    const getAllFraudMonitoringSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAllFraudMonitoringSummary(query_params));
    };
    const allRiskAssessmentSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAllMerchantRiskAssessments(query_params));
    };
    const allRiskSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAllRiskAssessmentsSummary(query_params));
    };
    const allPentestSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetAllPentestSummary(query_params));
        setLoadingContent(false);
    };

    const getMerchantBlockhainSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantBlockhainSummary(query_params));
        setLoadingContent(false);
    };

    const getMerchantCiiDirectiveSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantCiiDirectiveSummary(query_params));
        setLoadingContent(false);
    };
    const getMerchantGeneralCiiDirectiveSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantGeneralCiiDirectiveSummary(query_params));
        setLoadingContent(false);
    };
    const getMerchantGeneralBlockhainSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetMerchantGeneralBlockhainSummary(query_params));
        setLoadingContent(false);
    };
    const getFrameworkPercentageSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetFrameworkPercentageSummary(query_params));
        setLoadingContent(false);
    };
    const getCisoPercentageSummary = async () => {
        setLoadingContent(true);
        await dispatch(GetCisoPercentageSummary(query_params));
        setLoadingContent(false);
    };
    useEffect(() => {
        if (open) {
            getMerchantOverviewSummary();
            getAllPcidssSummary();
            getAllISO9001Summary();
            getISO22301Summary();
            getNdprSummary();
            getSoc2Summary();
            getSSLCSummary();
            getAlutomatedScans();
            getAllKDPASummary();
            getAllGDPASummary();
            getPeopleInfoSummary();
            getPeopleSummary();
            getPeopleTrainingInfoSummary();
            getMerchantIsoSummaryByAnnex();
            getMerchantIsoTwoSummaryByAnnex();
            getContinuityGroupSummary();
            getPeopleScanSummary();
            getInventoryScanSummary();
            getSourceCodeScanSummary();
            getAPISecurityScanSummary();
            getPCIcanSummary();
            getVulScanSummary();
            getAllVendorSummary();
            getAllKycSummary();
            getAllFraudMonitoringSummary();
            allRiskAssessmentSummary();
            allRiskSummary();
            allPentestSummary();
            getAutomatedScansSummary();
            getThirdPartyInfoSummary();
            getMerchantPCISLCSummary();
            getMerchantBlockhainSummary();
            getMerchantCiiDirectiveSummary();
            getMerchantGeneralBlockhainSummary();
            getMerchantGeneralCiiDirectiveSummary();
            getFrameworkPercentageSummary();
            getCisoPercentageSummary();

            getAllTrainingCourses();
            getAllEmployees();
        }
        // setLoadingContent(false);
    }, [open, query_params]);
    const handleCheckAllAutomatedScans = () => {
        if (automated_scans?.length > 0) {
            setAutomated_scans([]);
        } else {
            const filtered_checks = all_automated_scan_group?.map((item) => item?.name);
            setAutomated_scans(filtered_checks);
        }
    };
    function haveCommonItems(arr1, arr2) {
        return arr1.some((item) => arr2.includes(item));
    }

    // Frameworks
    const handlStabdardCheck = (scan) => {
        const modified_standards = [...standards];
        if (standards?.includes(scan)) {
            setStandards(modified_standards.filter((item) => item !== scan));
        } else {
            setStandards([...modified_standards, scan]);
        }
    };

    const handleCheckFrameworks = () => {
        if (standards?.length > 0) {
            setStandards([]);
        } else {
            setStandards(filtered_standards);
        }
    };

    // Risks
    const handleRiskCheck = (scan) => {
        const modified_risks = [...risk_options];
        if (risk_options?.includes(scan)) {
            setRisk_options(modified_risks.filter((item) => item !== scan));
        } else {
            setRisk_options([...modified_risks, scan]);
        }
    };
    const handleCheckAllRiskScans = () => {
        if (risk_options?.length > 0) {
            setRisk_options([]);
        } else {
            setRisk_options(riskOptions);
        }
    };

    // Employees
    const handleEmployeesCheck = (scan) => {
        const modified_employees = [...employees_options];
        if (employees_options?.includes(scan)) {
            setEmployees_options(modified_employees.filter((item) => item !== scan));
        } else {
            setEmployees_options([...modified_employees, scan]);
        }
    };
    const handleCheckAllEmployeesScans = () => {
        if (employees_options?.length > 0) {
            setEmployees_options([]);
        } else {
            setEmployees_options(employeeOptions);
        }
    };

    // Inventory
    const handleInventoryCheck = (scan) => {
        const modified_inventory = [...inventory_options];
        if (inventory_options?.includes(scan)) {
            setInventory_options(modified_inventory.filter((item) => item !== scan));
        } else {
            setInventory_options([...modified_inventory, scan]);
        }
    };
    const handleCheckAllInventoryScans = () => {
        if (inventory_options?.length > 0) {
            setInventory_options([]);
        } else {
            setInventory_options(inventoryOptions);
        }
    };

    // Inventory
    const handleThirdPartyCheck = (scan) => {
        const modified_ithird_party = [...third_party_options];
        if (third_party_options?.includes(scan)) {
            setThirdPartyOptions(modified_ithird_party.filter((item) => item !== scan));
        } else {
            setThirdPartyOptions([...modified_ithird_party, scan]);
        }
    };
    const handleCheckAllThirdPartyScans = () => {
        if (third_party_options?.length > 0) {
            setThirdPartyOptions([]);
        } else {
            setThirdPartyOptions(thirdpartyOptions);
        }
    };

    function getYearsSinceJoining() {
        const currentYear = new Date().getFullYear();
        const years = [];

        for (let year = yearUserSignedUp; year <= currentYear; year++) {
            years.push(year);
        }

        return years;
    }

    return (
        <>
            <Drawer
                open={open}
                anchor="right"
                TransitionComponent={Transition}
                transitionDuration={1000}
                keepMounted
                onClose={handleClose}
                PaperProps={{
                    sx: {
                        boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                        flex: 1,
                        width: '100%',
                        overflow: 'scroll',
                        zIndex: 9999,
                    },
                }}
                scroll="paper"
            >
                <div className="flex items-start bg-[#F8FAFC] fixed left-0 top-0 w-full">
                    <div className="bg-[#F1F5F9] border-r border-[#F1F5F9] p-3 w-[20%] h-[100vh] overflow-scroll">
                        <p className="text-[#395BA9] text-[13px] font-semibold">Customize Modules</p>
                        <div className="mt-3">
                            <FormControlLabel
                                label={<p className="text-sm text-gray-600 font-medium">Automated Scans</p>}
                                control={
                                    <Checkbox
                                        indeterminate={haveCommonItems([...new Set(filtered_checks)], automated_scans)}
                                        checked={haveCommonItems(filtered_checks, automated_scans)}
                                        onChange={handleCheckAllAutomatedScans}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                                color: '#395BA9',
                                            },
                                        }}
                                    />
                                }
                            />
                            <div className="mt-[-0.5rem]">
                                {filtered_checks?.map((scan) => (
                                    <div className="ml-3" key={scan}>
                                        <FormControlLabel
                                            sx={{ p: '0 !important', mb: '0 !important' }}
                                            label={<p className="text-xs text-gray-600 font-normal">{scan}</p>}
                                            control={
                                                <Checkbox
                                                    checked={automated_scans?.includes(scan)}
                                                    onChange={() => handleAutomatedScansCheck(scan)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 18,
                                                            color: '#395BA9',
                                                        },
                                                    }}
                                                />
                                            }
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3">
                            <FormControlLabel
                                label={<p className="text-sm text-gray-600 font-medium">Frameworks</p>}
                                control={
                                    <Checkbox
                                        indeterminate={haveCommonItems([...new Set(filtered_standards)], standards)}
                                        checked={haveCommonItems([...new Set(filtered_standards)], standards)}
                                        onChange={handleCheckFrameworks}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                                color: '#395BA9',
                                            },
                                        }}
                                    />
                                }
                            />
                            <div className="mt-[-0.5rem]">
                                {[...new Set(filtered_standards)]?.map((standard, index) => (
                                    <div className="ml-3" key={index}>
                                        <FormControlLabel
                                            sx={{ p: '0 !important', mb: '-0.5rem !important' }}
                                            label={
                                                <p className="text-xs text-gray-600 font-normal text-uppercase">
                                                    {standard}
                                                </p>
                                            }
                                            control={
                                                <Checkbox
                                                    checked={standards?.includes(standard)}
                                                    onChange={() => handlStabdardCheck(standard)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 18,
                                                            color: '#395BA9',
                                                        },
                                                    }}
                                                />
                                            }
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3">
                            <FormControlLabel
                                label={<p className="text-sm text-gray-600 font-medium">Risk Assessment</p>}
                                control={
                                    <Checkbox
                                        indeterminate={haveCommonItems([...new Set(riskOptions)], risk_options)}
                                        checked={haveCommonItems([...new Set(riskOptions)], risk_options)}
                                        onChange={handleCheckAllRiskScans}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                                color: '#395BA9',
                                            },
                                        }}
                                    />
                                }
                            />
                            <div className="mt-[-0.5rem]">
                                {riskOptions?.map((risk, index) => (
                                    <div className="ml-3" key={index}>
                                        <FormControlLabel
                                            sx={{ p: '0 !important', mb: '-0.5rem !important' }}
                                            label={<p className="text-xs text-gray-600 font-normal">{risk}</p>}
                                            control={
                                                <Checkbox
                                                    checked={risk_options?.includes(risk)}
                                                    onChange={() => handleRiskCheck(risk)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 18,
                                                            color: '#395BA9',
                                                        },
                                                    }}
                                                />
                                            }
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3">
                            <FormControlLabel
                                label={<p className="text-sm text-gray-600 font-medium">Employees</p>}
                                control={
                                    <Checkbox
                                        indeterminate={haveCommonItems(
                                            [...new Set(employeeOptions)],
                                            employees_options
                                        )}
                                        checked={haveCommonItems([...new Set(employeeOptions)], employees_options)}
                                        onChange={handleCheckAllEmployeesScans}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                                color: '#395BA9',
                                            },
                                        }}
                                    />
                                }
                            />
                            <div className="mt-[-0.5rem]">
                                {employeeOptions?.map((employee, index) => (
                                    <div className="ml-3" key={index}>
                                        <FormControlLabel
                                            sx={{ p: '0 !important', mb: '-0.5rem !important' }}
                                            label={<p className="text-xs text-gray-600 font-normal">{employee}</p>}
                                            control={
                                                <Checkbox
                                                    checked={employees_options?.includes(employee)}
                                                    onChange={() => handleEmployeesCheck(employee)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 18,
                                                            color: '#395BA9',
                                                        },
                                                    }}
                                                />
                                            }
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3">
                            <FormControlLabel
                                label={<p className="text-sm text-gray-600 font-medium">Inventory scan</p>}
                                control={
                                    <Checkbox
                                        indeterminate={haveCommonItems(
                                            [...new Set(inventoryOptions)],
                                            inventory_options
                                        )}
                                        checked={haveCommonItems([...new Set(inventoryOptions)], inventory_options)}
                                        onChange={handleCheckAllInventoryScans}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                                color: '#395BA9',
                                            },
                                        }}
                                    />
                                }
                            />
                            <div className="mt-[-0.5rem]">
                                {inventoryOptions?.map((inventory, index) => (
                                    <div className="ml-3" key={index}>
                                        <FormControlLabel
                                            sx={{ p: '0 !important', mb: '-0.5rem !important' }}
                                            label={<p className="text-xs text-gray-600 font-normal">{inventory}</p>}
                                            control={
                                                <Checkbox
                                                    checked={inventory_options?.includes(inventory)}
                                                    onChange={() => handleInventoryCheck(inventory)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 18,
                                                            color: '#395BA9',
                                                        },
                                                    }}
                                                />
                                            }
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="mt-3">
                            <FormControlLabel
                                label={<p className="text-sm text-gray-600 font-medium">Third Party Risks</p>}
                                control={
                                    <Checkbox
                                        indeterminate={haveCommonItems(thirdpartyOptions, third_party_options)}
                                        checked={haveCommonItems(thirdpartyOptions, third_party_options)}
                                        onChange={handleCheckAllThirdPartyScans}
                                        sx={{
                                            '& .MuiSvgIcon-root': {
                                                fontSize: 20,
                                                color: '#395BA9',
                                            },
                                        }}
                                    />
                                }
                            />
                            <div className="mt-[-0.5rem]">
                                {thirdpartyOptions?.map((thirdparty, index) => (
                                    <div className="ml-3" key={index}>
                                        <FormControlLabel
                                            sx={{ p: '0 !important', mb: '-0.5rem !important' }}
                                            label={<p className="text-xs text-gray-600 font-normal">{thirdparty}</p>}
                                            control={
                                                <Checkbox
                                                    checked={third_party_options?.includes(thirdparty)}
                                                    onChange={() => handleThirdPartyCheck(thirdparty)}
                                                    sx={{
                                                        '& .MuiSvgIcon-root': {
                                                            fontSize: 18,
                                                            color: '#395BA9',
                                                        },
                                                    }}
                                                />
                                            }
                                        />{' '}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className="w-[80%] h-[100vh]">
                        <div
                            className="flex justify-between items-center bg-[#F8FAFC] border-b border-[#E2E8F0] p-3"
                            style={{ zIndex: 100 }}
                        >
                            <div className="flex items-center gap-5 cursor-pointer" onClick={handleClose}>
                                <WestIcon color="#2B3674" sx={{ color: '#2B3674' }} />
                                <h4 className="text-[#2B3674] text-lg font-bold">CISO Report and Compliance Health</h4>
                            </div>
                            <div className="flex items-center gap-5">
                                <div>
                                    <button
                                        aria-controls={filterAnchorEl ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={filterAnchorEl ? 'true' : undefined}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setFilterAnchorEl(event.currentTarget);
                                        }}
                                        className="border border-[#E2E8F0] flex items-center justify-between w-full rounded p-2  h-9"
                                    >
                                        <p className="font-medium text-[13px] text-[#64748B]">Year: {filterYear}</p>

                                        <KeyboardArrowDownIcon sx={{ width: 14, height: 14, ml: 1 }} />
                                    </button>
                                    <Menu
                                        anchorEl={filterAnchorEl}
                                        open={Boolean(filterAnchorEl)}
                                        onClose={() => setFilterAnchorEl(null)}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                borderRadius: '4px',
                                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                            },
                                            '& .MuiList-root': {
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        <div className="!p-2 flex flex-col min-w-[224px]">
                                            {getYearsSinceJoining()?.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleYearChange(option);
                                                        setFilterAnchorEl(null);
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize',
                                                        borderBottom:
                                                            getYearsSinceJoining()?.length - 1 === index
                                                                ? ''
                                                                : '1px solid #F1F5F9',
                                                        padding: '6px 8px',
                                                    }}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    </Menu>
                                </div>
                                <div>
                                    <button
                                        aria-controls={viewAnchorEl ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={viewAnchorEl ? 'true' : undefined}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setViewAnchorEl(event.currentTarget);
                                        }}
                                        className="border border-[#E2E8F0] flex items-center justify-between w-full rounded p-2  h-9"
                                    >
                                        <p className="font-medium text-[13px] text-[#64748B]">View: {view}</p>

                                        <KeyboardArrowDownIcon sx={{ width: 14, height: 14, ml: 1 }} />
                                    </button>

                                    <Menu
                                        anchorEl={viewAnchorEl}
                                        open={Boolean(viewAnchorEl)}
                                        onClose={() => setViewAnchorEl(null)}
                                        sx={{
                                            '& .MuiPaper-root': {
                                                borderRadius: '4px',
                                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                            },
                                            '& .MuiList-root': {
                                                padding: 0,
                                            },
                                        }}
                                    >
                                        <div className="!p-2 flex flex-col min-w-[224px]">
                                            {viewOptions?.map((option, index) => (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setView(option);
                                                        setViewAnchorEl(null);
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize',
                                                        borderBottom:
                                                            viewOptions?.length - 1 === index
                                                                ? ''
                                                                : '1px solid #F1F5F9',
                                                        padding: '6px 8px',
                                                    }}
                                                >
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    </Menu>
                                </div>
                                <button
                                    className="bg-[#395BA9] text-white px-3 py-2 text-sm font-semibold rounded  flex items-center gap-1"
                                    onClick={async () => {
                                        setLoading(true);
                                        await generatePFDReport(itemLength);
                                        setLoading(false);
                                    }}
                                >
                                    {loading && <Loader2 className="h-6 w-6 animate-spin" />}
                                    Download Report
                                </button>
                            </div>
                        </div>
                        <div className="flex justify-center items-center bg-[#F8FAFC]">
                            <main className="w-[80%] my-4 h-[80vh] overflow-scroll">
                                <section
                                    className="relative h-screen bg-white p-5 rounded-md mb-4"
                                    style={{ zIndex: 1 }}
                                >
                                    <div className="absolute top-10 left-10">
                                        <Logo className="w-[180px]" />
                                    </div>

                                    <div className="absolute top-1/2 text-center left-1/2 -translate-y-1/2 -translate-x-1/2 transform w-full">
                                        <h2 className="text-[#334155] font-bold text-[36px] w-full">
                                            CISO REPORT AND COMPLIANCE HEALTH
                                        </h2>

                                        <p className="text-[#475569] font-medium text-xl mt-9">
                                            {moment(merchant_info?.date_created).format('MMMM Do, YYYY')} -{' '}
                                            {moment(new Date()).format('MMMM Do, YYYY')}
                                        </p>

                                        <h3 className="mt-20 text-[#475569] font-semibold text-[28px]">
                                            {merchant_info?.name}
                                        </h3>
                                    </div>
                                </section>
                                <section className="relative bg-white p-5 rounded-md mb-4">
                                    <h4 className="text-[#2B3674] text-[22px] font-bold text-center">
                                        Summary of the Report
                                    </h4>
                                    <ReportSummary1
                                        standards={standards}
                                        automated_scans={automated_scans}
                                        view={view}
                                    />
                                </section>
                                <section className="relative h-screen bg-white p-5 rounded-md mb-4">
                                    <ReportSummary2 />
                                </section>
                                {(view === 'Report View' || view === 'All') && automated_scans?.length > 0 && (
                                    <section className="relative bg-white p-3 rounded-md mb-4">
                                        <AutomatedScans />
                                    </section>
                                )}
                                {(view === 'Report View' || view === 'All') && standards?.length > 0 && (
                                    <section className="relative bg-white p-3 rounded-md mb-4">
                                        <Standards standards={standards} />
                                    </section>
                                )}
                                <section className="relative bg-white p-3 rounded-md mb-4">
                                    <RiskAssessment view={view} risk_options={risk_options} />
                                </section>
                                {(view === 'Graphical Presentation' || view === 'All') &&
                                    employees_options?.length > 0 && (
                                        <section className="relative h-screen  bg-white p-3 rounded-md mb-4">
                                            <Employees />
                                        </section>
                                    )}

                                {(view === 'Report View' || view === 'All') && employees_options?.length > 0 && (
                                    <section className="relative bg-white p-3 rounded-md mb-4">
                                        <EmployeesTable employees_options={employees_options} />
                                    </section>
                                )}
                                {(view === 'Graphical Presentation' || view === 'All') &&
                                    inventory_options?.length > 0 && (
                                        <section className="relative bg-white p-3 rounded-md mb-4">
                                            <InverntoryScan />
                                        </section>
                                    )}
                                {(view === 'Report View' || view === 'All') && inventory_options?.length > 0 && (
                                    <section className="relative bg-white p-3 rounded-md mb-4">
                                        <InventoryScansTable inventory_options={inventory_options} />
                                    </section>
                                )}
                                {third_party_options?.length > 0 && (
                                    <section className="relative bg-white p-3 rounded-md mb-4">
                                        <ThirdParty third_party_options={third_party_options} view={view} />
                                    </section>
                                )}
                            </main>
                        </div>
                    </div>
                </div>{' '}
                <Dialog
                    open={loadingContent}
                    PaperProps={{
                        sx: {
                            borderRadius: '8px !important',
                            padding: 0,
                            boxShadow: '0px 16px 24px 0px #00000026',
                            width: '413px',
                        },
                    }}
                >
                    <div className="flex items-center justify-center flex-col gap-2 py-10">
                        <Loader2 className="h-10 w-10 animate-spin" />

                        <p className="text-[#64748B] text-sm">Proccessing...</p>
                    </div>
                </Dialog>
            </Drawer>
            <div id="ciso-report">
                <SpoolReportPrint
                    // targetRef={targetRef}
                    open={open}
                    standards={standards}
                    employees_options={employees_options}
                    inventory_options={inventory_options}
                    automated_scans={automated_scans}
                    ciso_percentage_ciso_summary={ciso_percentage_ciso_summary}
                    view={view}
                    third_party_options={third_party_options}
                    risk_options={risk_options}
                    setItemLength={setItemLength}
                />
            </div>
        </>
    );
}

export default SpoolVendorReport;
