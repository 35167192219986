import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
//core components
import { List, ListItem, Stack } from '@mui/material';
import LoadingState from 'components/new_components/LoadingState';
import AppReportModal from 'components/new_components/AppReportModal';
import closeIcon from '../../../assets/img/closeReportModal.svg';
import bulb from '../../../assets/img/lightBulb.svg';
import pdfIcon from '../../../assets/img/pdf.svg';
import download from '../../../assets/img/download.svg';

//redux
import { connect } from 'react-redux';
import { GetMerchantPentestReportById } from 'store/actions/merchantActions';
import { formatDateObjectHandler } from 'utils';
import AppColorTag from 'components/new_components/AppColorTag';
import { GetDownloadLink } from 'store/actions/generalActions';

//translations

// import { GetDownloadLink } from 'store/actions/generalActions';

const PentestReportModal = (props) => {
    //props
    const {
        open,
        handleClose,
        title,
        subtitle,
        GetMerchantPentestReportById,
        report,
        pentest_report_by_id,
        GetDownloadLink,
    } = props;

    //state
    const [getReportsLoading, setGetReportLoading] = useState(false);

    //theme

    //translation

    const getPentestReports = async (id) => {
        setGetReportLoading(true);
        const res = await GetMerchantPentestReportById(id);
        setGetReportLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (report) {
            getPentestReports(report);
        }
    }, [report]);
    const [loadingLink, setLoadingLink] = useState(false);
    const getDownloadLink = async () => {
        if (report.report && !loadingLink) {
            setLoadingLink(true);
            const res = await GetDownloadLink('asvc_requests', 'pentest_report', report.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(report.report, '_blank');
            }
        }
    };
    return (
        <AppReportModal open={open} handleClose={handleClose} width={511}>
            <div className="flex items-end justify-between h-[52px] mb-[24px] ml-[24px] mr-[14px]">
                <h1 className="text-[18px] font-semibold text-[#202D66]">{title}</h1>
                <div onClick={handleClose} className=" flex items-center justify-center">
                    <img src={closeIcon} alt="close icon" className="cursor-pointer" />
                </div>
            </div>
            <Stack
                flexDirection="row"
                justifyContent="flex-start"
                sx={{
                    py: '8px',
                    px: '16px',
                    fontSize: 13,
                    fontWeight: 400,
                    color: '#64748B',
                    background: '#F8FAFC',
                    borderRadius: '4px',
                    ml: '24px',
                    mr: '14px',
                    display: 'flex',
                    gap: '10px',
                    alignItems: 'flex-start',
                    border: '1px solid #F0F0FA',
                }}
            >
                <img src={bulb} alt="bulb" />
                <span className="text-[#64748b] text-[13px]">{subtitle}</span>
            </Stack>
            <List sx={{ paddingTop: 0, mb: 3 }}>
                {getReportsLoading ? (
                    <LoadingState />
                ) : (
                    pentest_report_by_id
                        ?.sort((a, b) => b?.id - a?.id)
                        ?.map((report) => {
                            return (
                                <ListItem
                                    sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
                                    key={report?.id}
                                >
                                    <div className="flex items-center justify-between w-full mt-[16px] mb-[24px] ml-[24px] mr-[14px]">
                                        <div className="flex gap-[8px] items-center ml-[10px]">
                                            <img src={pdfIcon} alt="file icon" />
                                            <div className="flex flex-col items-start">
                                                <span className="text-[#475569] text-[15px]">{`${report?.name} ${report?.version}`}</span>
                                                <span className="text-[#94A3B8] text-[12px]">
                                                    {' '}
                                                    {formatDateObjectHandler(report?.date_created, 'MMMM Do, YYYY')}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="flex items-center gap-[8px]">
                                            <div onClick={getDownloadLink}>
                                                {loadingLink ? (
                                                    <LoadingState size={12} styles={{ width: 'fit' }} />
                                                ) : (
                                                    <img src={download} />
                                                )}
                                            </div>

                                            <div className="mt-[6px]">
                                                <AppColorTag
                                                    text={report?.severity}
                                                    type={
                                                        report?.severity === 'high'
                                                            ? 'error'
                                                            : report?.severity === 'medium'
                                                            ? 'warning'
                                                            : 'success'
                                                    }
                                                    fontWeight={500}
                                                    sx={{ marginLeft: '-30px', fontSize: '10px' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </ListItem>
                            );
                        })
                )}
            </List>
        </AppReportModal>
    );
};

const mapStateToProps = (state) => {
    return {
        pentest_report_by_id: state?.merchantReducers?.pentest_report_by_id,
    };
};
export default connect(mapStateToProps, { GetMerchantPentestReportById, GetDownloadLink })(PentestReportModal);
