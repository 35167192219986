import React from 'react';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmployeeSummary from './EmployeeSummary';
import { useSelector } from 'react-redux';

const ReportSummary2 = () => {
    const { inventory_scan_summary, all_third_party_summary, api_security_scan_summary } = useSelector(
        (state) => state?.CISOReducers
    );
    const failed_scans = api_security_scan_summary?.filter((scan) => scan?.status === 'failed');
    return (
        <div className="float-right mt-[5rem]">
            <EmployeeSummary />
            <div className="border border-[#F1F5F9] rounded py-2 my-3 w-[600px]">
                <div className="flex justify-between items-center px-3">
                    <h4 className="text-[#2B3674] text-base font-bold">Inventory Scans</h4>
                    <div className="flex items-center gap-1">
                        <FiberManualRecordIcon
                            sx={{
                                color:
                                    inventory_scan_summary?.total_percentage_passed < 50
                                        ? '#FF5449'
                                        : inventory_scan_summary?.total_percentage_passed >= 50 &&
                                          inventory_scan_summary?.total_percentage_passed <= 70
                                        ? '#F2C021'
                                        : '#55BE8B',
                                width: 12,
                                height: 12,
                            }}
                        />
                        <p className="text-gray-600 text-xs font-medium">
                            {inventory_scan_summary?.total_percentage_passed}%
                        </p>
                    </div>
                </div>
                {failed_scans?.length > 0 && (
                    <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                        <div className="flex items-center gap-1">
                            {inventory_scan_summary?.api_compliance_need_attention > 0 ? (
                                <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                            ) : (
                                <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                            )}
                            <p className="text-gray-600 text-xs font-normal">API Compliance</p>
                        </div>
                        <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium rounded">
                            {inventory_scan_summary?.api_compliance_need_attention} needs attention
                        </p>
                    </div>
                )}
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {inventory_scan_summary?.failed_code_reviews_coun > 0 ? (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        ) : (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal">Codebase Security Scan</p>
                    </div>
                    <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium rounded">
                        {inventory_scan_summary?.failed_code_reviews_count} needs attention
                    </p>
                </div>
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {inventory_scan_summary?.pen_test_high_severity ? (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        ) : (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal">Penetration Testing</p>
                    </div>
                    <div className="flex items-center gap-1 border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium rounded">
                        <FiberManualRecordIcon sx={{ color: '#FF5449', width: 12, height: 12 }} />
                        <p className="text-gray-500 text-xs font-medium">
                            {inventory_scan_summary?.pen_test_high_severity} high severity
                        </p>
                    </div>
                </div>
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {inventory_scan_summary?.pci_failed_scan ? (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        ) : (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal">PCI Scan</p>
                    </div>
                    <div className="flex items-center gap-1 border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium rounded">
                        <FiberManualRecordIcon sx={{ color: '#FF5449', width: 12, height: 12 }} />
                        <p className="text-gray-500 text-xs font-medium">
                            {' '}
                            {inventory_scan_summary?.pci_failed_scan} failed scans
                        </p>
                    </div>
                </div>
            </div>
            <div className="border border-[#F1F5F9] rounded py-2 my-3 w-[600px]">
                <div className="flex justify-between items-center px-3">
                    <h4 className="text-[#2B3674] text-base font-bold">Third Party Risks</h4>
                    <div className="flex items-center gap-1">
                        <FiberManualRecordIcon
                            sx={{
                                color:
                                    all_third_party_summary?.total_percentage_passed < 50
                                        ? '#FF5449'
                                        : all_third_party_summary?.total_percentage_passed >= 50 &&
                                          all_third_party_summary?.total_percentage_passed <= 70
                                        ? '#F2C021'
                                        : '#55BE8B',
                                width: 12,
                                height: 12,
                            }}
                        />
                        <p className="text-gray-600 text-xs font-medium">
                            {all_third_party_summary?.total_percentage_passed?.toFixed(2)}%
                        </p>
                    </div>
                </div>
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {all_third_party_summary?.aml_low_risk_count === all_third_party_summary?.total_aml ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal">Anit-Money-Laundering Check</p>
                    </div>
                    <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                        {all_third_party_summary?.aml_low_risk_count}/{all_third_party_summary?.total_aml}
                    </p>
                </div>
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {all_third_party_summary?.non_fraudulent_cases_count ===
                        all_third_party_summary?.total_fraud_monitoring ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal">Transaction Fraud Monitoring</p>
                    </div>
                    <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                        {all_third_party_summary?.non_fraudulent_cases_count}/
                        {all_third_party_summary?.total_fraud_monitoring}
                    </p>
                </div>
                <div className="border-t border-[#F1F5F9] px-3 p-2 flex justify-between items-center">
                    <div className="flex items-center gap-1">
                        {all_third_party_summary?.vendors_with_response === all_third_party_summary?.total_vendor ? (
                            <CheckCircleIcon sx={{ color: '#37A372', width: 12, height: 13 }} />
                        ) : (
                            <ErrorIcon sx={{ color: '#FF5449', width: 12, height: 13 }} />
                        )}
                        <p className="text-gray-600 text-xs font-normal">Vendor Mangement</p>
                    </div>
                    <p className="border border-[#E2E8F0] p-1 text-gray-500 text-xs font-medium">
                        {all_third_party_summary?.vendors_with_response}/{all_third_party_summary?.total_vendor}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ReportSummary2;
