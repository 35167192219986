import React from 'react';
import { useSelector } from 'react-redux';

const EmployeesTable = ({ employees_options }) => {
    const { people_summary, people_scan_summary } = useSelector((state) => state?.CISOReducers);
    const all_training = useSelector((state) => state?.trainingReducers?.all_training);
    const all_merchant_people = useSelector((state) => state?.merchantReducers?.all_merchant_people);

    const mapped_training = all_training?.map((course) => course?.course_info?.id) || [];
    const filtered_training =
        all_training?.filter((training, index) => mapped_training?.indexOf(training?.course_info?.id) !== index) || [];
    const filtered_merchant = all_merchant_people?.filter((merchant) => merchant?.training) || [];
    return (
        <div className="mt-[5rem]">
            <div hidden={people_summary?.length === 0 || !employees_options?.includes('Onboarding Policy')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Employees</p>
                </div>
                <table className="w-full w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Onboarding Policy
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Agent
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Identity Check
                            </th>
                        </tr>
                    </thead>
                    {people_summary?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.last_name} {option?.first_name}
                                </th>
                                <th
                                    className={`${
                                        option?.total_policies_downloaded === option?.total_policy_count
                                            ? 'text-[#55BE8B]'
                                            : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option?.total_policies_downloaded}/{option?.total_policy_count}
                                    {''} Completed
                                </th>
                                <th
                                    scope="row"
                                    className={`${
                                        option.ran_agent ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option.ran_agent ? 'Installed' : 'Uninstalled'}
                                </th>

                                <th
                                    scope="row"
                                    className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                >
                                    {option.run_kyc_check ? 'Completed' : 'Pending'}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div hidden={people_scan_summary?.length === 0 || !employees_options?.includes('Computer scan')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Computers</p>
                </div>
                <table className="w-full w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Owner
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Computer Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Password Manager
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                HD Encryption
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Anti-virus Installled
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Screenlock
                            </th>
                        </tr>
                    </thead>
                    {people_scan_summary?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.name}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.systemname}
                                </th>
                                <th
                                    className={`${
                                        option?.pwmanager ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option?.pwmanager ? 'Yes' : 'No'}
                                </th>
                                <th
                                    scope="row"
                                    className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                >
                                    {option?.diskencryption ? 'Yes' : 'No'}
                                </th>

                                <th
                                    className={`${
                                        option?.avstatus ? 'text-[#55BE8B]' : 'text-[#FF5449]'
                                    } text-xs font-normal p-3 text-center border-2 border-black`}
                                >
                                    {option?.avstatus ? 'Yes' : 'No'}
                                </th>
                                <th
                                    scope="row"
                                    className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black"
                                >
                                    {option?.lockstatus ? 'Yes' : 'No'}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
            <div hidden={filtered_training?.length === 0 || !employees_options?.includes('Employee Training')}>
                <div className="border-2 border-black bg-[#6E8EDF] flex justify-center items-center p-3">
                    <p className="text-white text-sm font-semibold">Employee Training</p>
                </div>
                <table className="w-full w-full">
                    <thead className="w-full">
                        <tr>
                            <th className="text-[#64748B] text-[13px] font-semibold p-2 text-center border-2 border-black">
                                Name
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Department
                            </th>
                            <th className="text-[#64748B] text-[13px]  font-semibold p-2 text-center border-2 border-black">
                                Employee Completed Training
                            </th>
                        </tr>
                    </thead>
                    {filtered_training?.map((option, index) => (
                        <tbody key={index}>
                            <tr>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {option?.course_info?.title}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black capitalize">
                                    {option?.course_info?.departments}
                                </th>
                                <th className="text-gray-500 text-xs font-normal p-3 text-center border-2 border-black">
                                    {filtered_merchant?.length}/{all_merchant_people?.length}
                                </th>
                            </tr>
                        </tbody>
                    ))}
                </table>
            </div>
        </div>
    );
};

export default EmployeesTable;
